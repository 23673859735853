<template>
    <BaseLayout>
        <div class="teamwork-dashboard-wrapper">
            <LayoutCreateNav class="pointer-events-none filter grayscale opacity-70" />
            <div class="mt-10 grid grid-cols-6 gap-10">
                <div class="col-start-1 col-span-4">
                    <div class="border bg-white custom_shadow rounded-lg h-full">
                        <div class="bg-primary-three border-b border-secondary-three flex justify-between items-center py-4 px-6 rounded-t-lg">
                            <h1 class="fw-700 fs-14 text-primary-one whitespace-nowrap">
                                {{ config.title }}
                            </h1>

                            <div class="flex">
                                <button class="pl-5">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" fill="#8D8D8D"/>
                                        <path d="M6 13.5C6.82843 13.5 7.5 12.8284 7.5 12C7.5 11.1716 6.82843 10.5 6 10.5C5.17157 10.5 4.5 11.1716 4.5 12C4.5 12.8284 5.17157 13.5 6 13.5Z" fill="#8D8D8D"/>
                                        <path d="M18 13.5C18.8284 13.5 19.5 12.8284 19.5 12C19.5 11.1716 18.8284 10.5 18 10.5C17.1716 10.5 16.5 11.1716 16.5 12C16.5 12.8284 17.1716 13.5 18 13.5Z" fill="#8D8D8D"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="px-6 pb-8 pt-7 min-h-150px">
                            <component
                                :card="card"
                                :series="series"
                                :categories="categories"
                                :is="charts.find(item => item.__name == config.chartType)" 
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <!-- Don't remove extra div element -->
                </div>
            </div>
            <RightSidebar :config="config" />
        </div>
    </BaseLayout>
</template>

<script setup>
    import { merge } from 'lodash';
    import { computed } from 'vue';
    import { useTeamDashboard } from '@/views/screens/dashboard/useTeamDashboard.js'
    import BaseLayout from "@/views/layouts/dashboard/BaseLayout.vue"
    import RightSidebar from '@/components/dashboard/sidebar/RightSidebar.vue'
    import LayoutCreateNav from '@/components/dashboard/LayoutCreateNav.vue'
    import { useConfiguration } from "@/views/screens/dashboard/useConfiguration.js"
    const mountable = { mountable: true };
    const { charts } = useTeamDashboard(mountable);
    
    const { config, selectedCard, series, categories } = useConfiguration(mountable);
   
    const card = computed(() => merge(selectedCard.value, {
        settings: {
            height: 556
        },
        configuration: {
            legend: true
        }
    }))
</script>
