<template>
    <div class="grid gap-2 mb-5">
        <h1 v-if="label" class="fs-12 fw-500 text-primary-one fs-14 fw-500 text-color-616161">{{ label }}</h1>
        <select v-model="selected" class="fs-14 fw-500 text-color-616161 border border-secondary-five rounded px-3 py-2">
            <option
                v-for="option in localOptions"
                :key="option[itemValue]"
                :value="option[itemValue]"
            >
                {{ option[itemText] }}
            </option>
        </select>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useRightSidebar } from '@/components/dashboard/sidebar/useRightSidebar.js';
    const { prepareSelectDropdownOptions } = useRightSidebar();
    const props = defineProps({
        label: [String, Boolean],
        defaultOption: {
            type: String,
            required: false
        },
        options: {
            type: Array,
            required: false
        },
        itemText: {
            type: String,
            default: () => 'title'
        },
        itemValue: {
            type: String,
            default: () => 'id'
        },
        value: [String, Number]
    });
    const emit = defineEmits(['input', 'change']);

    const selected = computed({
        get() {
            return props.value
        },
        set(value) {
            emit('input', value);
            emit('change', value);
        }
    });
    const configuration = {
        defaultOption: props.defaultOption,
        itemText     : props.itemText,
        itemValue    : props.itemValue
    }
    const localOptions = computed(() => {
        if(props.defaultOption) {
            return prepareSelectDropdownOptions(props.options, configuration)
        }
        return props.options;
    })
</script>