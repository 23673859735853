<template>
    <div>
        <div>
            <InputSelect
                label="Project Name"
                default-option="Select project"
                item-text="name"
                :options="projects"
                v-model="form.project_id"
                @change="getScenariosByProject"
            />

            <InputSelect
                v-if="form.project_id"
                label="Scenario Name"
                default-option="Select scenario"
                :options="scenarios"
                v-model="form.scenario_id"
            />

            <InputSelect
                v-if="form.scenario_id"
                label="Module Name"
                default-option="Select module"
                :options="modules"
                v-model="form.module_id"
                @change="(module_id) => {
                    getLevelData(module_id)
                    _getAssessmentList(form.scenario_id, form.module_id)
                }"
            />

            <div class="flex gap-2 justify-between items-center mb-2">
                <InputRadio
                    v-if="config.chartType == 'AssessmentChart'"
                    :data="assessmentRadioData"
                    v-model="form.selectedDataType"
                />
            </div>

            <InputSelect
                v-show="form.selectedDataType == 'AssessmentData'"
                label="Assessment"
                default-option="Select Assessment"
                :options="assessments"
                v-model="form.assessment_id"
            />

            <InputSelect
                v-if="form.module_id && form.target_id >= 1"
                label="First level"
                default-option="Select first level data"
                :options="l1Data"
                v-model="form.l1Data"
                @change="getSecondLevelData"
            />

            <InputSelect
                v-if="form.l1Data && form.target_id >= 2"
                label="Second level"
                default-option="Select second level data"
                :options="l2Data"
                v-model="form.l2Data"
                @change="getThirdLevelData"
            />

            <InputSelect
                v-if="form.l2Data && form.target_id >= 3"
                label="Third level"
                default-option="Select third level data"
                :options="l3Data"
                v-model="form.l3Data"
            />

            <div class="grid grid-cols-2 my-2">
                <label class="fw-500 select-none cursor-pointer mb-2 block">
                    <Checkbox label="Level 1" v-model="form.level_1" @checked="(checked) => handleLevel(form, 'level_1', checked)" class="text-16" />
                </label>
                <label class="fw-500 select-none cursor-pointer mb-2 block">
                    <Checkbox label="Level 2" v-model="form.level_2" @checked="(checked) => handleLevel(form, 'level_2', checked)" class="text-16" />
                </label>
                <label class="fw-500 select-none cursor-pointer mb-2 block">
                    <Checkbox label="Level 3" v-model="form.level_3" @checked="(checked) => handleLevel(form, 'level_3', checked)" class="text-16" />
                </label>
                <label class="fw-500 select-none cursor-pointer mb-2 block">
                    <Checkbox label="Level 4" v-model="form.level_4" @checked="(checked) => handleLevel(form, 'level_4', checked)" class="text-16" />
                </label>
            </div>


            <div v-if="form.project_id && form.scenario_id && form.module_id" class="mb-2">
                <div @click="showFilter = !showFilter" class="flex justify-between items-center fs-14 fw-500 text-color-616161 border border-secondary-five rounded px-3 py-2 cursor-pointer">
                    Element Filter
                    <svg class="transform w-3 h-3" :class="showFilter && 'rotate-180'" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m16 6.594-.719.687-12.5 12.5L4.22 21.22 16 9.437 27.781 21.22l1.438-1.438-12.5-12.5Z"/></svg>
                </div>
                <div v-show="showFilter" class="fs-14 fw-500 text-color-616161 border border-secondary-five rounded px-3 py-2 mt-2 select-none">
                    <InputTreeSelect
                        class="fs-14 fw-500 text-color-616161"
                        :options="filterData"
                        :response="form.filterTreeData"
                        :selectedValue="form.filterTreeData"
                    />
                </div>
            </div>
                
            <div v-for="(property, index) in form.properties" :key="index">
                <Header
                    v-if="config.chartType != 'AreaChart'"
                    :title="`Parameter ${index + 1}`"
                    @addNewProperty="addNewProperty(form)"
                    @removeProperty="removeProperty(form.properties, index)"
                />
                <InputSelect
                    default-option="Select property"
                    :options="properties"
                    v-model="property.property_id"
                    @change="handleProperty(property)"
                />
                <ul class="grid gap-2 mb-5">
                    <li v-for="(parameter, parameterIndex) in property.parameters" :key="parameterIndex" class="flex justify-between items-center">
                        <Checkbox 
                            :label="parameter.title" 
                            @checked="(value) => {
                                parameter.parameter_id = value ? parameter.id : null
                                handleParameter(property.parameters, parameter.parameter_id)
                            }"
                            :value="!!parameter.parameter_id" 
                            class="text-16" 
                        />
                        <ColorPicker v-model="parameter.color" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, watchEffect } from 'vue'
    import axios from 'axios'
    import InputTreeSelect from '@/components/dashboard/sidebar/components/right-sidebar/InputTreeSelect.vue'
    import InputSelect from  './InputSelect.vue'
    import Header from '@/components/dashboard/sidebar/components/right-sidebar/Header.vue'
    import Checkbox from './Checkbox.vue'
    import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js'
    import { useConfiguration } from '@/views/screens/dashboard/useConfiguration.js'
    import ColorPicker from '@/components/dashboard/sidebar/components/right-sidebar/ColorPicker.vue'
    import InputRadio from '@/components/dashboard/sidebar/components/right-sidebar/InputRadio.vue'
    
    const props = defineProps({
        form: Object,
        config: Object
    })

    const { 
        assessmentRadioData 
    } = useConfiguration()

    const showFilter = ref(false)

    const {
        l1Data,
        l2Data,
        l3Data,
        levels,
        assessments,
        chartTypes,
        dataSources,
        targetSource,
        projects,
        scenarios,
        modules,
        properties,
        parameters,
        getSecondLevelData,
        handleLevel,
        getThirdLevelData,
        getScenariosByProject,
        getPropertiesByModule,
        getParameterByProperty,
        getLevelDataByScenarioAndModule,
        getAssessmentDataByScenarioAndModule
    } = useProjectData({mountable: true, form: props.form});

    const addNewProperty = (form) => {
        form.properties.push({property_id: null, parameter_id: null});
    }

    const removeProperty = (properties, index) => {
        if(properties.length == 1 || !confirm('Are you sure to remove this item ?')) return
        properties.splice(index, 1);
    }

    const getLevelData = (moduleId) => {
        getPropertiesByModule(moduleId);
        getLevelDataByScenarioAndModule(props.form.scenario_id, moduleId)
    }

    const _getAssessmentList = (scenario_id, module_id) => {
        if(scenario_id && module_id){
            getAssessmentDataByScenarioAndModule(scenario_id, module_id)
        }
    }

    const handleProperty = (property) => {
        property.parameters = getParameterByProperty(property.property_id).map(item => ({
            id: item.id,
            title: item.title,
            parameter_id: null,
            color: ''
        }))
    }

    const handleParameter = (parameters, parameter_id) => {
        if(props.config.chartType != "AssessmentChart") return
        parameters.forEach(parameter => {
            if(parameter.parameter_id != parameter_id){
                parameter.parameter_id = null
            }
        })
    }

    let clearTimeoutId = null
    const filterData = ref([])

    const getFilterData = async (form) => 
    {
        if(!form.scenario_id || !form.module_id) return
        clearTimeout(clearTimeoutId)
        clearTimeoutId = setTimeout(async () => {
            let { data } = await axios.get(`communicators/system/${form.scenario_id}/micro-format-data?module_id=${form.module_id}`).then(({data}) => data)
            filterData.value = data
        }, 1000)
    }

    watchEffect(() => {
        getFilterData(props.form)
    })
</script>
