<template>
    <label class="relative cursor-pointer">
        <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="10.3223" width="9.67771" height="6" fill="#19A77C"/>
            <rect width="9.67771" height="5.25" transform="matrix(1 0 0 -1 10.3223 12)" fill="#0085FF"/>
            <rect width="9.67771" height="6" transform="matrix(-1 0 0 1 9.67773 0)" fill="#D6D6D6"/>
            <rect x="9.67773" y="12" width="9.67771" height="5.25" transform="rotate(-180 9.67773 12)" fill="#FE5656"/>
        </svg>                                
        <input v-model="color" type="color" class="absolute opacity-0 top-0">
    </label>
</template>

<script setup>
    import { computed } from 'vue'
    const props = defineProps({
        value: [String, Boolean]
    })    
    const emit = defineEmits()
    const color = computed({
        get() {
            return props.value
        },
        set(value) {
            emit('input', value);
            emit('change', value);
        }
    });
</script>

<style lang="scss" scoped>

</style>