<template>
    <div class="right_side_wrapper fixed top-0 right-0 bg-white overflow-y-auto" style="z-index: 99999999">
        <div class="sticky top-0 p-8 z-10 flex justify-between items-center border-b border-secondary-four bg-primary-three">
            <div class="flex items-center gap-3">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.0625 10.3125L12 14.25L15.9375 10.3125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 3.75L12 13.5" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 12.75V19.5C21 19.6989 20.921 19.8897 20.7803 20.0303C20.6397 20.171 20.4489 20.25 20.25 20.25H3.75C3.55109 20.25 3.36032 20.171 3.21967 20.0303C3.07902 19.8897 3 19.6989 3 19.5V12.75" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Chart Configuration
            </div>

            <svg @click="routes.go(-1)" class="cursor-pointer text-red" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.75 8H16.5" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.5 1.25L17.25 8L10.5 14.75" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div class="p-8">
            <ChartInfo :config="config" />
            
            <!-- primary axis start -->
                <button @click="showPrimaryAxisConfig = !showPrimaryAxisConfig" class="uppercase flex justify-between items-center w-full fs-12 fw-700 border-b border-secondary-four cursor-pointer py-2 mb-3">
                    Primary Axis
                    <svg class="transform rotate-0" :class="showPrimaryAxisConfig && 'rotate-180'" width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.25 7.25L8 1L1.75 7.25" stroke="#8D8D8D" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>                    
                </button>
                
                <div v-show="showPrimaryAxisConfig" key="primaryAxis">
                    <InputSelect
                        label="Chart Types"
                        default-option="Select type"
                        :options="chartTypes"
                        v-model="primaryChartType"
                        @change="setChartType(config.forms, 'primary', primaryChartType)"
                    />
                    
                    <template v-for="(form, index) in config.forms">
                        <div :key="index">
                            <div v-if="form.axis == 'primary'">
                                <DataConfiguration 
                                    :length="get(config, 'forms.length')"
                                    @addNew="addNewItem(defaultData, config, 'primary', primaryChartType)"
                                    @remove="removeItem(index, config.forms)"
                                    :isShown="form.showConfigComponent"
                                    @toggleComponent="(value) => form.showConfigComponent = value"
                                />

                                <div v-if="form.showConfigComponent">
                                    <InputRadio
                                        :data="templateSwitchData"
                                        v-model="form.selectedComponentName"
                                    />
                                    <components
                                        :form="form"
                                        :config="config"
                                        class="mt-2 mb-2"
                                        :is="components[form.selectedComponentName]" 
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            <!-- primary axis end -->
            
            <!-- secondary axis start -->
                <button @click="showSecondaryAxisConfig = !showSecondaryAxisConfig" class="uppercase flex justify-between items-center w-full fs-12 fw-700 border-b border-secondary-four cursor-pointer py-2 mb-3">
                    Secondary Axis
                    <svg class="transform rotate-0" :class="showSecondaryAxisConfig && 'rotate-180'" width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.25 7.25L8 1L1.75 7.25" stroke="#8D8D8D" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>                    
                </button>
                
                <div v-show="showSecondaryAxisConfig" key="secondaryAxis">
                    <InputSelect
                        label="Chart Types"
                        default-option="Select type"
                        :options="chartTypes"
                        v-model="secondaryChartType"
                        @change="setChartType(config.forms, 'secondary', secondaryChartType)"
                    />

                    <template v-for="(form, index) in config.forms">
                        <div :key="index">
                            <div v-if="form.axis == 'secondary'">
                                <DataConfiguration 
                                    :length="get(config, 'forms.length')"
                                    @addNew="addNewItem(defaultData, config, 'secondary', secondaryChartType)"
                                    @remove="removeItem(index, config.forms)"
                                    :isShown="form.showConfigComponent"
                                    @toggleComponent="(value) => form.showConfigComponent = value"
                                />

                                <div v-if="form.showConfigComponent">
                                    <InputRadio
                                        :data="templateSwitchData"
                                        v-model="form.selectedComponentName"
                                    />
                                    <components
                                        :form="form"
                                        :config="config"
                                        class="mt-2 mb-2"
                                        :is="components[form.selectedComponentName]" 
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            <!-- secondary axis end -->

        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import ChartInfo from './components/right-sidebar/ChartInfo.vue'
    import ProjectData from './components/right-sidebar/ProjectData.vue'
    import CustomData from './components/right-sidebar/CustomData.vue'
    import { useConfiguration } from '@/views/screens/dashboard/useConfiguration.js'
    import { useRightSidebar } from '@/components/dashboard/sidebar/useRightSidebar.js'
    import DataConfiguration from './components/right-sidebar/DataConfiguration.vue'
    import InputRadio from './components/right-sidebar/InputRadio.vue'
    import routes from '@/routes/routes.js'
    import InputSelect from  '@/components/dashboard/sidebar/components/right-sidebar/InputSelect.vue'
    import { get } from 'lodash'
    const props = defineProps({
        config: Object
    });

    const showPrimaryAxisConfig   = ref(false)
    const showSecondaryAxisConfig = ref(false)
    const primaryChartType   = ref('column')
    const secondaryChartType = ref('column')

    const { defaultData, templateSwitchData } = useConfiguration()
    const {
        chartTypes,
        addNewItem,
        removeItem,
        setChartType,
        hasAxis,
    } = useRightSidebar()

    const components = {
        ProjectData,
        CustomData
    }

    onMounted(() => {
        primaryChartType.value   = props.config.forms.find(item => item.axis == 'primary').chartTypes
        secondaryChartType.value = props.config.forms.find(item => item.axis == 'secondary').chartTypes
    })
</script>

<style scoped>
    .right_side_wrapper{
        width: 480px;
        height: 100%;
        box-shadow: -4px 0px 12px rgba(35, 39, 47, 0.1);
    }
</style>