<template>
    <div class="mb-10">
        <div class="flex justify-end">
            <ColorPicker v-model="form.color" />
        </div>
        <div class="border border-secondary-five rounded mt-2">
            <div class="border-b border-secondary-five grid grid-cols-2 fs-14 fw-500 bg-primary-three text-color-616161">
                <div class="p-3 border-r border-secondary-five">Custom Categories</div>
                <div class="p-3 ">
                    <input 
                        type="text" 
                        v-model="form.legendTitle" 
                        placeholder="Type here" 
                        class="bg-transparent"
                    >
                </div>
            </div>
            <div v-for="(item, index) in form.seriesData" :key="index" class="relative border-b border-secondary-five grid grid-cols-2 fs-14 fw-500 text-color-616161">
                <input placeholder="Enter Category" v-model="item.x" class="p-3 border-r border-secondary-five" />
                <input placeholder="Enter Variable name" v-model="item.y" class="p-3" />
                <button @click="removeItem(index, form.seriesData)" class="absolute top-2 right-0 hover:text-red-400">
                    <svg class="transform scale-75" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g id="web-app" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="close" fill="currentColor">
                                <polygon id="Shape" points="10.6568542 12.0710678 5 6.41421356 6.41421356 5 12.0710678 10.6568542 17.7279221 5 19.1421356 6.41421356 13.4852814 12.0710678 19.1421356 17.7279221 17.7279221 19.1421356 12.0710678 13.4852814 6.41421356 19.1421356 5 17.7279221"></polygon>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
            <button @click="form.seriesData.push({...placeholderData})" class="p-3 fs-14 fw-600 bg-primary-three w-full text-center text-primary-four">
                + Add New Row
            </button>
        </div>
    </div>
</template>

<script setup>
    import ColorPicker from '@/components/dashboard/sidebar/components/right-sidebar/ColorPicker.vue'

    const placeholderData = {x: '', y: ''}
    const props = defineProps({
        form: Object
    })

    const removeItem = (index, array) => {
        array.splice(index, 1)
    }
</script>

<style scoped>
</style>