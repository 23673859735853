import { isArray, find, cloneDeep } from "lodash";
const chartTypes = [
    {id: 'column', title: 'Column'},
    {id: 'line', title: 'Line'}
];
export const useRightSidebar = () => {
    const prepareSelectDropdownOptions = (options, config) => {
        const { defaultOption, itemValue = 'id', itemText = 'title' } = config;
        if(!find(options, [itemText, defaultOption]) && isArray(options) && defaultOption) {
            options.unshift({
                [itemValue]: null,
                [itemText]: defaultOption
            });
        }
        return options;
    }

    const _getCustomData = (forms) => {
        let firstCustomData = forms.find(item => {
            return item.selectedComponentName == 'CustomData'
        })

        firstCustomData = cloneDeep(firstCustomData)
        return firstCustomData.seriesData.map(data => {
            data.y=''
            return data
        })
    }

    const addNewItem = (defaultData, config, _axis, chartType) => {
        let forms = config.forms
        let localDefaultData = cloneDeep(defaultData)

        if(config.chartType == "CustomDataChart"){
            localDefaultData.selectedComponentName = 'CustomData'
            localDefaultData.seriesData = _getCustomData(forms)
        }

        localDefaultData.axis = _axis
        localDefaultData.chartTypes = chartType
        forms.push(localDefaultData)
    }

    const setChartType = (forms, axis, chartType) => {
        forms.forEach(item => {
            if(item.axis == axis)
                item.chartTypes = chartType
        })
    }
    
    const removeItem = (index, data) => {
        if(data.length==1 || !confirm('Are you sure to remove this data ?')) return
        data.splice(index, 1)
    }
    const handleTemplateSwitch = (item, form) => {
        form.selectedComponentName = item.key
    }

    const hasAxis = (forms, axis) => {
        return forms.find(item => item.axis = axis)
    }

    return {
        chartTypes,
        addNewItem,
        removeItem,
        handleTemplateSwitch,
        prepareSelectDropdownOptions,
        setChartType,
        hasAxis
    }
}