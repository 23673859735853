<template>
    <div>
        <div @click="(showDropdown = !showDropdown)" class="flex justify-between select-none pb-2 border-b border-secondary-four cursor-pointer fw-700 fs-12 mb-5">
            Chart Info
            <svg :class="showDropdown && 'transform rotate-180'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.25 12.5L10 6.25L3.75 12.5" stroke="#8D8D8D" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>                
        </div>
        <div v-if="showDropdown">
            <div class="grid gap-2 mb-5">
                <h1 class="fs-12 fw-500 text-primary-one fs-14 fw-500 text-color-616161">Chart Title</h1>
                <input type="text" 
                    v-model="config.title"
                    placeholder="Type here" 
                    class="border border-secondary-five rounded px-3 py-2 fs-14 fw-500 text-color-616161 " />
            </div>
            <InputSelect
                label="Type"
                default-option="Select chart"
                :options="chartTypes"
                v-model="config.chartType"
                @change="resetConfig(config.chartType)"
            />
            <InputSelect
                v-if="config.chartType == 'AssessmentChart'"
                label="Horizontal Categories"
                default-option="Select"
                :options="horizontalCategoryType"
                v-model="config.horizontalCategoryType"
            />


            <div class="grid gap-2 mb-5">
                <h1 class="fs-12 fw-500 text-primary-one fs-14 fw-500 text-color-616161">Y-Axis Left Label</h1>
                <input type="text" 
                    v-model="config.yAxisLeftLabel"
                    placeholder="Type here" 
                    class="border border-secondary-five rounded px-3 py-2 fs-14 fw-500 text-color-616161 " />
            </div>
            <div class="grid gap-2 mb-5">
                <h1 class="fs-12 fw-500 text-primary-one fs-14 fw-500 text-color-616161">Y-Axis Right Label</h1>
                <input type="text" 
                    v-model="config.yAxisRightLabel"
                    placeholder="Type here" 
                    class="border border-secondary-five rounded px-3 py-2 fs-14 fw-500 text-color-616161 " />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { cloneDeep } from 'lodash'
    import { ref, onMounted } from 'vue';
    import InputSelect from  './InputSelect.vue'
    import { useConfiguration } from '@/views/screens/dashboard/useConfiguration.js'
    
    const { config, chartTypes, defaultConfig, horizontalCategoryType, templateSwitchData } = useConfiguration()
    const showDropdown = ref(true)
    const resetConfig = (chartType) => 
    {
        config.value.forms = cloneDeep(defaultConfig.forms);
        
    }
</script>