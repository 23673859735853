<template>
    <div class="mb-10">
        <label class="mb-5 relative select-none h-48 border-dashed border-2 border-secondary-five text-center rounded bg-secondary-three grid items-center">
            <div class="grid justify-center">
                <svg class="mx-auto mb-4" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25 28H6.99902C6.73381 28 6.47945 27.8946 6.29192 27.7071C6.10438 27.5196 5.99902 27.2652 5.99902 27V5C5.99902 4.73478 6.10438 4.48043 6.29192 4.29289C6.47945 4.10536 6.73381 4 6.99902 4H19L26 11V27C26 27.1313 25.9741 27.2614 25.9239 27.3827C25.8736 27.504 25.8 27.6143 25.7071 27.7071C25.6143 27.8 25.504 27.8736 25.3827 27.9239C25.2614 27.9741 25.1313 28 25 28Z" stroke="#2B80AD" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                    <path d="M19 4V11H26.001" stroke="#2B80AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 17H20" stroke="#2B80AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 21H20" stroke="#2B80AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> 
                <h2 class="fs-14 fw-500 text-color-616161 mb-3">Drag file to upload or</h2>
                <button class="mx-auto bg-primary-four px-5 py-2 rounded text-white fs-14 fw-600 w-32 mb-2">Brows File</button>
                <h2 class="fs-12 fw-500 text-secondary-two">Supported files are XLS, CSV</h2>
                <input type="file" @input="handleFile" class="absolute top-0 right-0 w-full h-full opacity-0" />
            </div>               
        </label>
        <h1 class="text-secondary-two fw-400 fs-12 text-center mb-2">Need Excel file format?</h1>
        <a href="#" class="text-primary-four rounded py-3 block w-full text-center border border-primary-four hover:bg-primary-four hover:text-white hover:border-primary-four">
            Download Sample file
        </a>
    </div>
</template>

<script setup>
    const handleFile = (e) => 
    {
        let file = e.target.files[0]
        let FR = new FileReader()
        FR.readAsText(file)

        FR.onload = () => {
            let result = FR.result
        }
    }
</script>

<style scoped>
    .checked{
        display: none;
    }
    .custom_radio input:checked + .checked{
        display: block;
    }
    .custom_radio input:checked + .checked + .unChecked{
        display: none;
    }
</style>