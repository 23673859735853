<template>
    <div class="flex gap-6" >
        <label 
            v-for="item in data" 
            :key="item.key" 
            @click="handle(item)"
            class="flex gap-2 cursor-pointer select-none"
            :class="item.disabled && 'pointer-events-none opacity-30'"
        >
            <input type="radio" hidden :value="item.key" v-model="selected" />
            <div class="custom_radio relative w-5 h-5 rounded-full grid items-center">
                <span v-if="item.key == selected" class="checked">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" fill="#3D3D3D" stroke="#3D3D3D" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                        <path d="M12.4375 7.125L7.85414 11.5L5.5625 9.3125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                <span v-else class="unChecked">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>                                           
                </span>
            </div>
            {{ item.label }}
        </label>
    </div>
</template>

<script setup>
    import { computed } from 'vue'
    const props = defineProps({
        data: Array,
        handle: {
            type: Function,
            default: () => {}
        },
        value: [String, Number]
    })

    const emit = defineEmits(['input', 'change']);
    const selected = computed({
        get() {
            return props.value
        },
        set(value) {
            emit('input', value);
            emit('change', value);
        }
    });
</script>

<style scoped>
</style>