<template>
    <div class="flex justify-between mb-2">
        <div> {{ title }} </div>
        <div class="ml-auto">
            <button @click="$emit('addNewProperty')" title="Add new Item" class="w-5 h-5 text-white fw-600 fs-13 rounded-full">
                <svg class="ml-auto mr-4" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#8D8D8D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
                    <path d="M6.875 10H13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 6.875V13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <button @click="$emit('removeProperty')" title="Remove new Item" class="w-5 h-5 text-white fw-600 fs-13 rounded-full ml-1" style="rotate: 45deg">
                <svg class="ml-auto mr-4" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#8D8D8D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
                    <path d="M6.875 10H13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 6.875V13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
    </div>
</template>
<script setup>
    defineProps({
        title: String
    })
</script>