<template>
    <div>
        <div class="flex items-center justify-between select-none pb-2 border-b border-secondary-four cursor-pointer fw-700 fs-12 mb-5">
            Data Group
            <div class="flex items-center gap-5">
                <button @click="$emit('addNew')" class="px-3 py-2 text-white fw-600 fs-13 rounded bg-primary-four">+ Add New</button>
                <button
                    title="Remove Item" 
                    @click="$emit('remove')" 
                    class="w-5 h-5 text-white fw-600 fs-13 rounded-full"
                    style="rotate: 45deg"
                    :class="length == 2 && 'invisible'"
                >
                    <svg class="ml-auto mr-4" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#8D8D8D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"/>
                        <path d="M6.875 10H13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 6.875V13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                
                <svg @click="handleShow" :class="isShown&&'transform rotate-180'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.25 12.5L10 6.25L3.75 12.5" stroke="#8D8D8D" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { useConfiguration } from '@/views/screens/dashboard/useConfiguration.js'

    const props = defineProps({
        length: [Number, String],
        isShown: Boolean
    });
    const { presentationType } = useConfiguration()

    const emit = defineEmits()
    const show = ref(props.isShown)
    const handleShow = () => {
        show.value = !show.value
        emit('toggleComponent', show)
    }
</script>

<style scoped>
    .checked{
        display: none;
    }
    .custom_radio input:checked + .checked{
        display: block;
    }
    .custom_radio input:checked + .checked + .unChecked{
        display: none;
    }
</style>