<template>
    <div>
        <h2 class="fs-12 fw-500 mb-4 text-primary-one">Input Type</h2>
        <InputRadio 
            :data="templateSwitchData"
            v-model="selectedComponentName"
        />
        <components class="mt-2" :form="form" :is="components[selectedComponentName]" />
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import DataSeries from './DataSeries.vue'
    import Import from './Import.vue'
    import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js'
    import { useConfiguration } from '@/views/screens/dashboard/useConfiguration.js'
    import InputRadio from './InputRadio.vue'

    const { isComboChart } = useConfiguration()
    const { chartTypes } = useProjectData()
    const components = {
        DataSeries,
        Import
    }

    const templateSwitchData = ref([
        {
            key: 'Import',
            label: 'Excel File',
            disabled: true
        },
        {
            key: 'DataSeries',
            label: 'Insert Manually',
            disabled: false
        }
    ])

    const props = defineProps({
        form: Object
    })
    const selectedComponentName = ref('DataSeries')
</script>